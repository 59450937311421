import React from 'react'
import { CrimsafeLogoAuthorized } from '../components/images'
import { Layout } from '../components/layout'
import { Carousel } from '../components/images/carousel'
import { SEO } from '../components/seo'
import { EndeavourTheme, useEndeavourTheme } from '../hooks-and-hocs'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

export default () => {
  const styled = style(useEndeavourTheme())
  const { data, loading, error } = useQuery(
    gql`
      query {
        getCustomer(id: "225e6550-620d-11ea-8fe7-03ee89fd9c77") {
          id
          firstName
          lastName
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Home" meta={[]} />
      <Carousel />
      <section style={{ textAlign: 'center' }}>
        <h3 style={{ ...styled.heading, ...styled.padding }}>Welcome</h3>
        <p style={styled.padding}>
          Endeavour Screens is an award winning, Gold Coast family owned business specializing in security <br /> doors and screens. For over 30 years
          we have proudly delivered the highest quality, greatest value & <br /> the very best service for security screens and doors.
        </p>
        {!loading && data && console.log(data)}
        <CrimsafeLogoAuthorized style={styled.padding} />
      </section>
    </Layout>
  )
}

interface HomeStyleProps {
  heading: React.CSSProperties
  padding: React.CSSProperties
}

const style = (theme: EndeavourTheme): HomeStyleProps => ({
  heading: {
    color: theme.secondaryColor,
  },
  padding: {
    padding: '14px',
  },
})
